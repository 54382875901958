import { states } from '@/conditions/states';

const getTagsType = (tag) => states.tagsType.find((item) => item.value === tag);

export class CartEventCondition {
  constructor(data = {}) {
    this.minOrderSum = data.min_order_sum || 0;
    this.maxOrderSum = data.max_order_sum || 0;
    this.minLimit = data.min_limit || 0;
    this.maxLimit = data.max_limit || 1;
    this.defaultLimit = data.default_value || 1;
    this.tags = data.tags || [];
    this.tagsTypes = data.tags_types ? getTagsType(data.tags_types.toLowerCase()) : getTagsType('or');
    this.position = data.position || 0;
    this.id = data.id || null;
    this.carteventId = data.cartevent_id || null;
    this.createdTime = data.created || null;
    this.modifiedTime = data.modified || null;
  }

  get deserialized() {
    return {
      id: this.id,
      min_order_sum: this.minOrderSum || 0,
      max_order_sum: this.maxOrderSum,
      min_limit: this.minLimit || 0,
      max_limit: this.maxLimit || 1,
      default_value: this.defaultLimit || 1,
      tags: this.tags.map((el) => el.title),
      tags_types: this.tagsTypes.value,
      position: this.position,
    };
  }
}
export const serializeCartEventCondition = (val) => new CartEventCondition(val);

export const serializeCartEventConditionList = (list) => list.map(serializeCartEventCondition);

export const deserializeCartEventCondition = (toCartEventCondition) => {
  const cartEventCondition = new CartEventCondition();
  Object.assign(cartEventCondition, toCartEventCondition);
  return cartEventCondition.deserialized;
};

export const deserializeCartEventConditionList = (list) => list.map(deserializeCartEventCondition);

class ModerationItem {
  constructor(item) {
    this.foodTypeTitle = item.food_type_title || '';
    this.currentValues = {
      title: item.current_values ? item.current_values.title : null,
      price: item.current_values ? item.current_values.price : null,
      sellingText: item.current_values ? item.current_values.description : null,
      logo: item.current_values ? item.current_values.image : null,
    };
    this.moderationValues = {
      title: item.moderation_values ? item.moderation_values.title : null,
      price: item.moderation_values ? item.moderation_values.price : null,
      sellingText: item.moderation_values ? item.moderation_values.description : null,
      logo: item.moderation_values ? item.moderation_values.image : null,
    };
    if (item.changed_values && item.changed_values.length > 0) {
      const values = [];
      item.changed_values.forEach((element) => {
        // eslint-disable-next-line no-nested-ternary
        values.push(element === 'image' ? 'logo' : element === 'description' ? 'selling_text' : element);
      });
      this.changedValues = values;
    } else { this.changedValues = []; }
  }
}

export const serializeModerationItem = (item) => new ModerationItem(item);

<template>
  <VApp id="app">
    <RouterView v-if="authServiceIsReady" :key="$route.fullPath"/>
    <VProgressCircular
v-else
color="#E43A35"
indeterminate
:size="80"
:width="6"/>
  </VApp>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['authServiceIsReady']),
  },
};

</script>
<style lang="scss">
@import "assets/fonts/fonts.css";
.v-application {
  font-family: 'AvenirNextCyr' !important;
}
.v-data-table th, .v-data-table td {
  font-size: 16px !important;
}
.v-progress-circular {
  top: 50%;
  left: 50%;
}
</style>

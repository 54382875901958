export class TopCategory {
  constructor(category) {
    this.activeIcon = category ? category.active_icon : undefined;
    this.inactiveIcon = category ? category.inactive_icon : undefined;
    this.pk = category ? category.pk : undefined;
    this.position = category ? category.position : undefined;
    this.title = category ? category.title : undefined;
  }
}

export const serializeTopCategory = (category) => new TopCategory(category);

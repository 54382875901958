export class Hub {
  constructor(data = {}) {
    this.id = data.hub_id || 0;
    this.title = data.title || '';
    this.isReducedAreaActive = data.is_reduced_area_active;
    this.paymentMethods = data.payment_methods;
    this.allRestsCount = data.all_rests_cnt || 0;
    this.activeRestsCount = data.active_rests_cnt || 0;
    this.restaurants = data.restaurants || {};
    this.intersections = data.intersections || {};
    this.hotTariff = data.hot_tariff || null;
    this.isHotTariffActive = data.hot_tariff != null || false;
    this.isFreeDeliveryActive = data.is_free_delivery_active || false;
    this.freeDeliveryStartDt = data.free_delivery_start_dt || '';
    this.freeDeliveryEndDt = data.free_delivery_end_dt || '';
  }

  get deserialized() {
    return {
      hub_id: this.id || undefined,
      title: this.title || '',
      is_reduced_area_active: this.isReducedAreaActive,
      payment_methods: this.paymentMethods,
      all_rests_cnt: this.allRestsCount || undefined,
      active_rests_cnt: this.activeRestsCount || undefined,
      hot_tariff: this.hotTariff,
      is_free_delivery_active: this.isFreeDeliveryActive,
      free_delivery_start_dt: this.freeDeliveryStartDt,
      free_delivery_end_dt: this.freeDeliveryEndDt,
    };
  }
}

export const serializeHub = (hub) => new Hub(hub);
export const serializeHubsList = (hubs) => hubs.map(serializeHub);

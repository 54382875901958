import { deserializeRestaurantCommissionList, serializeRestaurantCommissionList } from '@/serializers/contractRestaurantCommissionSerializer';
import { Contract } from './ContractSerializer';
import { serializeContactsList, deserializeContactsList } from './contractContactSerializer';
import { serializeRestaurantContractsList, deserializeRestaurantContractsList } from './contractRestaurantContractSerializer';
import { serializeContractsRestaurantsList, deserializeContractsRestaurantsList } from './contractRestaurantSerializer';

export default class ExtendedContract extends Contract {
  constructor(data = {}) {
    super(data || {});
    this.restaurantsCommissions = data.restaurants_commissions
      ? serializeRestaurantCommissionList(data.restaurants_commissions)
      : [];
    this.restaurantsContracts = data.restaurants_contracts
      ? serializeRestaurantContractsList(data.restaurants_contracts)
      : [];
    this.restaurants = data.restaurants
      ? serializeContractsRestaurantsList(data.restaurants || [])
      : [];
    this.contacts = data.contacts
      ? serializeContactsList(data.contacts)
      : [];
  }

  get deserialized() {
    return {
      ...super.deserialized,
      commissions: [],
      restaurants_commissions: deserializeRestaurantCommissionList(this.restaurantsCommissions),
      restaurants_contracts: deserializeRestaurantContractsList(this.restaurantsContracts),
      restaurants: deserializeContractsRestaurantsList(this.restaurants),
      contacts: deserializeContactsList(this.contacts),
    };
  }
}

export const serializeExtendedContract = (contract) => new ExtendedContract(contract);

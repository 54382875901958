export class ConditionCost {
  constructor(data) {
    this.cost = data ? data.cost : null;
    this.type = data ? data.type : null;
  }

  get deserialized() {
    return {
      cost: this.cost,
      type: this.type,
    };
  }
}

export const serializeConditionCost = (cost) => new ConditionCost(cost);
export const serializeConditionCostList = (costs) => costs.map(serializeConditionCost);

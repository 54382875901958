import Vue from 'vue';
import Axios from 'axios';
import * as Sentry from '@sentry/browser';
import { serializeCollectionList, serializeCollection, deserializeCollection } from '@/serializers/collectionSerializer';
import { serializeCollectionPresetList } from '@/serializers/collectionPresetSerializer';
import { serializeChipList, deserializeChip } from '@/serializers/chipSerializer';
import { deserializeCommission } from '@/serializers/contractCommissionSerializer';
import { deserializeIncomePartner, serializeIncomePartnersList } from '@/serializers/incomePartnerSerializer';
import { serializeStoryPersonalized } from '@/serializers/storyPersonalizationSerializer';
import { serializeIntegrationList, deserializeIntegration } from '@/serializers/integrationSerializer';
import { serializeRestaurantChoices } from '@/serializers/restaurantChoicesSerializer';
import { serializeRestaurant, serializeRestaurantList } from '@/serializers/restaurantSerializer';
import { serializeRestaurantPhone } from '@/serializers/restaurantPhoneSerializer';
import { serializeRestaurantIVRPhonesList, serializeRestaurantIVRPhone } from '@/serializers/restaurantIVRPhoneSerializer';
import { serializeHub, serializeHubsList } from '@/serializers/hubSerializer';
import { serializeHubRestaurantsList } from '@/serializers/hubRestaurantSerializer';
import { serializeHubRestaurantsListShort } from '@/serializers/hubRestaurantShortSerializer';
import {
  serializeDeliveryTariff,
  serializeDeliveryTariffList,
} from '@/serializers/deliveryTariffSerializer';
import {
  serializeConditionCost,
  serializeConditionCostList,
} from '@/serializers/conditionCostSerializer';
import { serializeCategory, serializeCategoryList } from '@/serializers/categorySerializer';
import { serializeImageLink } from '@/serializers/imageSerializer';
import { serializeCityList, serializeCityListShort } from '@/serializers/citySerializer';
import { serializeDeliveryAreaList } from '@/serializers/deliveryAreaSerializer';
import { serializeDeliveryTimeList } from '@/serializers/deliveryTimeSerializer';
import { serializePromotion, serializePromotionList } from '@/serializers/promotionSerializer';
import { serializeExtendedRestaurant } from '@/serializers/extendedRestaurantSerializer';
import { serializeFood } from '@/serializers/foodSerializer';
import {
  serializeTopRestaurant,
  serializeTopRestaurantList,
} from '@/serializers/topRestaurantSerializer';
import { serializeBanner, serializeBannersList, deserializeBanner } from '@/serializers/bannerSerializer';
import { serializeModerationRestaurant } from '@/serializers/moderationRestaurantSerializer';
import { serializeContractsList } from '@/serializers/ContractSerializer';
import { serializeExtendedContract } from '@/serializers/ContractExtendedSerializer';
import { serializeContact } from '@/serializers/contractContactSerializer';
import {
  deserializeFoodTypeOrModifier,
  serializeFoodTypeOrModifier,
  serializeFoodTypeOrModifierList,
} from '@/serializers/foodTypeOrModifierSerializer';
import {
  deserializeFoodOrOption,
  serializeFoodOrOption,
  serializeFoodOrOptionList,
} from '@/serializers/foodOrOptionSerializer';
import { serializeHotTariffsList } from '@/serializers/hotTariff';
import { serializeFoodLabelList, deserializeFoodLabel } from '@/serializers/foodLabelsSerializer';
import { deserializeStory, serializeStory, serializeStoriesList } from '@/serializers/storySerializer';
import { deserializeSlide, serializeSlide, serializeSlidesList } from '@/serializers/slideSerializer';
import { deserializeSuggestion, serializeSuggestion, serializeSuggestionsList } from '@/serializers/suggestionSerializer';
import { serializeSynonymsRequestList, deserializeSynonymsRequest } from '@/serializers/synonymsSerializer';
import { serializeCartEventList, serializeCartEvent, deserializeCartEvent } from '@/serializers/cartEventSerializer';
import { serializeCartEventConditionList } from '@/serializers/cartEventConditionSerializer';
import { serializePartnersList, deserializePartners, serializePartners } from '@/serializers/partnersSerializer';
import { serializePartnerRestaurantsList, deserializePartnerRestaurants } from '@/serializers/partnerRestaurantSerializer';
import { serializeNotification, serializeNotificationsList, deserializeNotification } from '@/serializers/notificationSerializer';
import { deserializeContractPrepayment, serializeContractPrepaymentList } from '@/serializers/contractPrepaymentSerializer';
import { eventHub } from '@/global/eventhub';
import { serializeIntegrationDataList } from '@/serializers/IntegrationDataSerializer';

const api = Axios.create({
  baseURL: '/',
  timeout: 30000,
});

const iiko = Axios.create({
  baseURL: '/api-iiko/',
  timeout: 30000,
});

let count = 0;
function setInterceptors(instance, setToken = true) {
  instance.interceptors.request.use(
    (conf) => {
      count += 1;
      localStorage.setItem('requestsCount', count);
      eventHub.$emit('before-request');
      if (setToken) {
        // eslint-disable-next-line no-param-reassign
        conf.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      }
      // eslint-disable-next-line no-param-reassign
      conf.headers['Content-Type'] = 'application/json';
      return conf;
    },
    (error) => {
      eventHub.$emit('request-error', error.request.data.detail);
      return Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    (response) => {
      count -= 1;
      localStorage.setItem('requestsCount', count);
      eventHub.$emit('after-response');
      return response;
    },
    (error) => {
      count -= 1;
      localStorage.setItem('requestsCount', count);
      const responseData = error.response ? error.response.data : error;
      const responseDataError = responseData.error
        || responseData.detail || responseData.errors || responseData.errorDescription;
      let errorMsg = 'Произошла неизвестная ошибка, но как бороться с ней мы уже знаем!';
      if (!Axios.isCancel(error)) {
        if (responseDataError) {
          errorMsg = responseDataError;
        }
        // TODO: add parse Validation error
        const ids = error.config.url.match(/(\w+\d+)/gmi);
        let path = error.config.url;
        if (ids !== null) {
          ids.forEach((el) => {
            path = path.replace(el, '{ oid }');
          });
        }
        Sentry.captureMessage(
          new Error(`🤯 ${path} 🤯`),
          {
            extra: {
              path: error.config.url,
              method: error.config.method,
              body: error.config.data,
            },
            fingerprint: ['{{ default }}', error.config.url],
          },
        );
        eventHub.$emit('response-error', errorMsg);
        if (errorMsg.restaurants) {
          Object.values(errorMsg.restaurants).forEach((restaurantErrorList) => {
            restaurantErrorList.forEach((restaurantErrorMsg) => {
              eventHub.$emit('response-error', `Ошибка копирования. ${restaurantErrorMsg}`);
            });
          });
        } else {
          eventHub.$emit('response-error', errorMsg);
        }
      } else {
        return Promise.reject(error.message);
      }
      return Promise.reject(error);
    },
  );
}

setInterceptors(api);
setInterceptors(iiko, false);

export const fetchRestaurantChoices = () => api.get('/api/restaurants/choices/')
  .then((response) => serializeRestaurantChoices(response.data));

let source;
// eslint-disable-next-line max-len
export const fetchRestaurants = (city, title, deliveryService, state, email, hubs, offset, limit, categoryIds = '') => {
  if (source) {
    source.cancel('The request cancellation was caused патамушта запрос обновился');
  }
  const { CancelToken } = Axios;
  source = CancelToken.source();
  const params = {
    city_title: city || undefined,
    title: title || undefined,
    will_be_delivered_by: deliveryService || undefined,
    state: state || undefined,
    manager_email: email || undefined,
    offset: offset || 0,
    limit: limit || 20,
    has_hubs: hubs || undefined,
    category_ids: categoryIds?.length ? categoryIds.join(',') : undefined,
  };
  return api.get('/api/restaurants/',
    {
      cancelToken: source.token,
      params,
    })
    .then((response) => serializeRestaurantList(response.data || []))
    .catch(() => {});
};

export const createHub = (hub) => api.post('/api/hubs/', hub)
  .then((response) => serializeHub(response.data));

export const updateHub = (hub) => api.put(`/api/hubs/${hub.id}/`, hub)
  .then((response) => serializeHub(response.data));

export const deleteHub = (pk) => api.delete(
  `/api/hubs/${pk}/`,
);

export const fetchHubs = (params = undefined) => api
  .get(
    '/api/hubs/',
    {
      params,
    },
  ).then((response) => {
    const { data } = response;
    return {
      currentPage: data.current_page || 1,
      limit: data.limit || 0,
      totalPages: data.total_page || 0,
      data: serializeHubsList(data.data),
    };
  });

export const fetchHubRestaurants = (params = undefined) => api
  .get(
    '/api/hubs/restaurants/',
    {
      params,
    },
  ).then((response) => {
    const { data } = response;
    return {
      currentPage: data.current_page || 1,
      limit: data.limit || 0,
      totalPages: data.total_page || 0,
      data: serializeHubRestaurantsList(data.data),
      dataShort: serializeHubRestaurantsListShort(data.data),
    };
  });

export const patchHub = (pk, params) => api.patch(`/api/hubs/${pk}/`, { ...params })
  .then((response) => response.data);

export const fetchRestaurant = (pk) => api.get(`/api/restaurants/${pk}/`)
  .then((response) => serializeRestaurant(response.data));

export const createRestaurant = (restaurant) => api.post('/api/restaurants/', restaurant)
  .then((response) => serializeRestaurant(response.data));

export const updateRestaurant = (pk, restaurant) => api.put(`/api/restaurants/${pk}/`, restaurant.deserialized)
  .then((response) => serializeRestaurant(response.data));

export const patchRestaurant = (pk, data) => api.patch(`/api/restaurants/${pk}/`, data)
  .then((response) => serializeRestaurant(response.data));

export const createRestaurantPhone = (restaurantPhone) => api.post(
  '/api/restaurant_phones/', restaurantPhone,
).then((response) => serializeRestaurantPhone(response.data));

export const updateRestaurantPhone = (restaurantPhone) => api.put(
  `/api/restaurant_phones/${restaurantPhone.id}/`, restaurantPhone,
).then((response) => serializeRestaurantPhone(response.data));

export const deleteRestaurantPhone = (restaurantId, restaurantPhoneId) => api.delete(
  `/api/restaurants/${restaurantId}/restaurant_phones/${restaurantPhoneId}/`,
);

export const updateRestaurantState = (pk, state) => api.patch(`/api/restaurants/${pk}/state/`, { state });

export const getRestaurantPerformance = (pk) => api.get(`/api/restaurants/${pk}/performance/`)
  .then((response) => response.data);

export const fetchCategories = (searchText = '') => api
  .get(`/api/categories/?search_text=${searchText}`)
  .then((response) => serializeCategoryList(response.data));

export const deleteCategory = (categoryId) => api.delete(`/api/categories/${categoryId}/`)
  .then((response) => response);

export const createCategory = (category) => api.post('/api/categories/', category.deserialized)
  .then((response) => serializeCategory(response.data));

export const updateCategory = (categoryId, categoryData) => api.put(
  `/api/categories/${categoryId}/`, categoryData.deserialized,
).then((response) => serializeCategory(response.data));

export const uploadImage = (imageLink, config) => Axios.post(imageLink.url, imageLink.formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  ...config,
}).then((response) => response);

export const uploadCsvFile = (formData, restId) => api.post(`/api/delivery_areas/restaurants/${restId}/import/`, formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}).then((response) => response);

export const getPresignedLink = (folderName) => api.post(`/api/images/${folderName}`)
  .then((response) => serializeImageLink(response.data));

export const fetchConditionCosts = () => api.get('/api/condition_costs/')
  .then((response) => serializeConditionCostList(response.data));

export const createConditionCost = (conditionCost) => api.post('/api/condition_costs/', conditionCost)
  .then((response) => serializeConditionCost(response.data));

export const fetchDeliveryTariffs = () => api.get('/api/delivery_tariffs/')
  .then((response) => serializeDeliveryTariffList(response.data));

export const createDeliveryTariff = (tariffData) => api.post('/api/delivery_tariffs/', tariffData)
  .then((response) => serializeDeliveryTariff(response.data));

export const updateDeliveryTariff = (tariffPk, tariffData) => api.put(`/api/delivery_tariffs/${tariffPk}/`, tariffData)
  .then((response) => serializeDeliveryTariff(response.data));

export const deleteDeliveryTariff = (tariffPk) => api.delete(`/api/delivery_tariffs/${tariffPk}/`);

export const fetchHotDeliveryTariffs = (params) => api.get(
  '/api/hot-tariffs/',
  {
    params: {
      ...params,
    },
  },
)
  .then((response) => ({
    totalPages: response.data.total_pages,
    totalCount: response.data.total_count,
    data: serializeHotTariffsList(response.data.data),
  }));

export const patchHotTariffState = (id, isActive) => api.patch(
  `/api/hot-tariffs/${id}/`,
  {
    is_active: isActive,
  },
);

export const createHotTariff = (newTariff) => api.post(
  '/api/hot-tariffs/',
  newTariff,
);

export const fetchCityList = () => api.get('/api/cities/')
  .then((response) => serializeCityList(response.data));

export const fetchCityListShort = () => api.get('/api/cities/')
  .then((response) => serializeCityListShort(response.data));

export const patchCity = (pk, data) => api.patch(`/api/cities/${pk}/`, data);

export const fetchDeliveryAreaWithTariffList = (restaurantPk, isReduced) => api.get(`/api/delivery_areas/tariffs?restaurants=${restaurantPk}&is_reduced_area=${isReduced}`)
  .then((response) => serializeDeliveryAreaList(response.data[0].delivery_areas));

export const deliveryAreaAddConditions = (
  areaKeys,
  restaurantPk,
  tariffPk,
  deliveryTimePk,
  isReducedArea = false,
) => api
  .put('/api/delivery_areas/', {
    area_keys: areaKeys,
    restaurant_id: restaurantPk,
    delivery_tariff_id: tariffPk,
    delivery_time_id: deliveryTimePk,
    is_reduced_area: isReducedArea,
  })
  .then((response) => serializeDeliveryAreaList(response.data));

export const deliveryAreaDeleteConditions = (areaKeys, restaurantPk, isReducedArea = false) => api
  .delete('/api/delivery_areas/', {
    data: {
      restaurant_id: restaurantPk,
      area_keys: areaKeys,
      is_reduced_area: isReducedArea,
    },
  });

export const fetchDeliveryTimes = () => api.get('/api/delivery_times/')
  .then((response) => serializeDeliveryTimeList(response.data));

let requestInProgress;

export const fetchPromotions = (params, page) => {
  if (requestInProgress) {
    requestInProgress.cancel('Operation canceled, патамушта поисковые данные обновились 🚬');
  }
  const { CancelToken } = Axios;
  requestInProgress = CancelToken.source();
  const query = {
    cities: params.cities?.length > 0 ? params.cities.join(',') : undefined,
    header: params.headerTitle || undefined,
    restaurant_title: params.restaurantTitle || undefined,
    delivery_service: params.deliveryService || undefined,
    page,
    limit: params.defaultLimit,
    active: params.activePromotions,
  };
  return api
    .get(
      '/api/v2/promotions/',
      {
        params: query,
        cancelToken: requestInProgress.token,
      },
    )
    .then((response) => (
      {
        promotions: serializePromotionList(response.data.data),
        total_count: response.data.total_count,
      }
    ));
};

export const createPromotion = (promotionData) => api.post('/api/promotions/', promotionData)
  .then((response) => serializePromotion(response.data));

export const updatePromotion = (promotionPk, promotionData) => api.put(`/api/promotions/${promotionPk}/`, promotionData)
  .then((response) => serializePromotion(response.data));

export const copyPromotionToRestaurants = (promotionPk, restaurantPks) => api
  .post(`/api/promotions/${promotionPk}/copy/`, { target_restaurant_ids: restaurantPks });

export const fetchExtendedRestaurant = (restaurantPk, showInactiveFoodsAndTypes = true) => api
  .get(
    `/menu-api/api/v1/menu/${restaurantPk}/`,
    {
      params: {
        show: showInactiveFoodsAndTypes,
      },
    },
  )
  .then((response) => serializeExtendedRestaurant(response.data));

export const patchFood = (restaurantPk, foodTypeOid, foodOid, dataFood) => api.patch(
  `/menu-api/api/v1/menu/${restaurantPk}/food_types/${foodTypeOid}/foods/${foodOid}/`,
  dataFood,
)
  .then((response) => serializeFood(response.data));

export const fetchTopRestaurant = (
  offset, restaurantTitle, cityTitle, limit = 20,
) => api.get('/api/v2/top_restaurants/',
  {
    params: {
      restaurant_title: restaurantTitle || undefined,
      city_title: cityTitle || undefined,
      offset,
      limit,
    },
  }).then((response) => serializeTopRestaurantList(response.data));

export const createTopRestaurant = (restaurantPk, newTop) => api.post(`/api/top_restaurants/${restaurantPk}/`, newTop.deserialized)
  .then((response) => serializeTopRestaurant(response.data));

export const deleteTopRestaurant = (topPk) => api.delete(`/api/top_restaurants/${topPk}/`);

export const bannerPageLimit = 24;

const bannersWithCount = (response) => (response.data
  ? {
    banners: serializeBannersList(response.data.results),
    count: response.data.total_count,
  }
  : {
    banners: [],
    count: 0,
  });

export const getBannerByTitle = (title, isActive) => api.get('/api/banners/',
  {
    params: {
      title,
      page_size: 100,
      is_active: +isActive,
    },
  })
  .then(
    (response) => bannersWithCount(response),
  );

export const getBanners = (page = 1) => api.get('/api/banners/',
  {
    params: {
      page_size: bannerPageLimit,
      page,
      show_all: true,
    },
  })
  .then((response) => bannersWithCount(response));

export const createBanner = (banner) => api.post('/api/banners/', deserializeBanner(banner)).then(
  (response) => serializeBanner(response.data),
);

export const updateBanner = (banner) => api.put(`/api/banners/${banner.id}/`, deserializeBanner(banner))
  .then((response) => serializeBanner(response.data));

export const fetchModerationRestaurantsList = (payload) => api.get('/api/v3/moderation/restaurants/', { params: payload })
  .then((response) => (response.data));

export const fetchModerationRestaurant = (pk) => api.get(`/api/v3/moderation/restaurant/${pk}/`)
  .then((response) => serializeModerationRestaurant(response.data));

export const postModerationDocument = (documentId, changes) => api.put(`/api/v3/moderation/${documentId}/`, changes)
  .then((response) => response.data);

export const fetchContracts = (params = undefined) => api
  .get(
    '/api/contracts/',
    {
      params,
    },
  ).then((response) => {
    const { data } = response;
    const serializedResponse = {
      currentPage: data.current_page || 1,
      lastPage: data.last_page || 1,
      total: data.total || 0,
      canDelete: data.can_delete || false,
      results: serializeContractsList(data.results),
    };
    return serializedResponse;
  });

export const createContract = (contract) => api.post('/api/contracts/', contract).then(
  (response) => serializeExtendedContract(response.data),
);

export const updateContract = (contract) => api
  .put(`/api/contracts/${contract.id}/`, contract);

export const deleteContract = (contractId) => api.delete(`/api/contracts/${contractId}/`);

export const fetchContract = (contractId) => api.get(`/api/contracts/${contractId}/`)
  .then((response) => serializeExtendedContract(response.data));

export const addContractsContact = (contractId, contact) => api.post(
  `/api/contracts/${contractId}/contacts/`,
  contact,
).then((response) => serializeContact(response.data));

export const updateContractsContact = (contractId, contact) => api.put(
  `/api/contracts/${contractId}/contacts/${contact.id}/`,
  contact,
);

export const deleteContractsContact = (contractId, contactId) => api.delete(
  `/api/contracts/${contractId}/contacts/${contactId}/`,
);

export const createContractCommission = (contractId, commission) => api.post(
  `/api/contracts/${contractId}/restaurant_commission/`,
  deserializeCommission(commission),
);

export const updateContractCommission = (commission) => api.patch(
  `/api/contracts/restaurant_commission/${commission.id}/`,
  deserializeCommission(commission),
);

export const getContractLogs = (contractId, page) => api.get(
  `/api/contracts/${contractId}/logs/`,
  {
    params: {
      page,
      page_size: 15,
    },
  },
).then((response) => response.data);
export default api;

export const getUsers = (userEmail) => api.get(
  '/api/users/',
  {
    params: {
      search: userEmail,
    },
  },
).then((response) => response.data);

// below v3

export const fetchFoodTypesAndModifiers = (restaurantPk, params) => api
  .get(`/api/v3/extended_restaurants/${restaurantPk}/category/`, {
    params,
  }).then((response) => {
    response.data.items = serializeFoodTypeOrModifierList(response.data.items);
    return response.data;
  });

export const fetchFoodTypes = (restaurantPk) => api
  .get(`/api/v3/extended_restaurants/${restaurantPk}/category/`, {
    params: {
      is_category: true,
      limit: 5000,
    },
  }).then((response) => serializeFoodTypeOrModifierList(response.data.items));

export const fetchModifiers = (restaurantPk) => api
  .get(`/api/v3/extended_restaurants/${restaurantPk}/category/`, {
    params: {
      is_modifier: true,
      limit: 5000,
    },
  }).then((response) => serializeFoodTypeOrModifierList(response.data.items));

export const fetchFoodsAndOptions = (restaurantPk, params) => api
  .get(`/api/v3/extended_restaurants/${restaurantPk}/food/`, {
    params,
  }).then((response) => {
    response.data.items = serializeFoodOrOptionList(response.data.items);
    return response.data;
  });

export const fetchFoods = (restaurantPk, queryString = '') => api
  .get(`/api/v3/extended_restaurants/${restaurantPk}/food/${queryString}`, {
    params: {
      is_food: true,
      limit: 5000,
    },
  }).then((response) => serializeFoodOrOptionList(response.data.items));

export const fetchOptions = (restaurantPk) => api
  .get(`/api/v3/extended_restaurants/${restaurantPk}/food/`, {
    params: {
      is_option: true,
      limit: 5000,
    },
  }).then((response) => serializeFoodOrOptionList(response.data.items));

export const createFoodTypeOrModifier = (restaurantPk, newFoodTypeOrModifier) => api
  .post(`/api/v3/extended_restaurants/${restaurantPk}/category/`, deserializeFoodTypeOrModifier(newFoodTypeOrModifier))
  .then((response) => serializeFoodTypeOrModifier(response.data));

export const updateFoodTypeOrModifier = (restaurantPk, foodTypeOrModifier) => api
  .put(`/api/v3/extended_restaurants/${restaurantPk}/category/${foodTypeOrModifier.id}/`, deserializeFoodTypeOrModifier(foodTypeOrModifier))
  .then((response) => serializeFoodTypeOrModifier(response.data));

export const deleteFoodTypeOrModifier = (restaurantPk, id) => api
  .delete(`/api/v3/extended_restaurants/${restaurantPk}/category/${id}/`);

export const createFoodOrOption = (restaurantPk, newFoodOrOption) => api
  .post(`/api/v3/extended_restaurants/${restaurantPk}/food/`, deserializeFoodOrOption(newFoodOrOption))
  .then((response) => serializeFoodOrOption(response.data));

export const updateFoodOrOption = (restaurantPk, foodOrOption) => api
  .put(`/api/v3/extended_restaurants/${restaurantPk}/food/${foodOrOption.id}/`, deserializeFoodOrOption(foodOrOption))
  .then((response) => serializeFoodOrOption(response.data));

export const deleteFoodOrOption = (restaurantPk, id) => api
  .delete(`/api/v3/extended_restaurants/${restaurantPk}/food/${id}/`);

export const copyMenu = (restaurantPk, copyFromRestaurantPk) => api
  .post(`/api/v3/extended_restaurants/${restaurantPk}/copy_menu/${copyFromRestaurantPk}`);

export const fetchFoodLabels = (params) => api
  .get('/api/labels/', { params }).then((response) => {
    response.data.data = serializeFoodLabelList(response.data.data);
    return response.data;
  });

export const createFoodLabel = (params) => api
  .post('/api/label/', deserializeFoodLabel(params))
  .then((response) => response.data);

export const updateFoodLabel = (labelId, params) => api
  .put(`/api/label/${labelId}/`, deserializeFoodLabel(params))
  .then((response) => response.data);

export const deleteFoodLabel = (labelId) => api
  .delete(`/api/label/${labelId}/`);

export const synchronizeFoodLabels = () => api.post('/api/labels/synchronize/');

export const synchronizeFoodLabelById = (labelId) => api.post(`/api/label/${labelId}/synchronize/`);

export const getRestaurantIVRPhone = (restaurantPk) => api
  .get(`/api/ivr_phones/${restaurantPk}/`).then((response) => serializeRestaurantIVRPhonesList(response.data));

export const createRestaurantIVRPhone = (payload) => api.post('/api/ivr_phones/', payload)
  .then((response) => serializeRestaurantIVRPhone(response.data));

export const updateRestaurantIVRPhone = (ivrId, payload) => api.put(`/api/ivr_phones/${ivrId}/`, payload)
  .then((response) => serializeRestaurantIVRPhone(response.data));

export const deleteRestaurantIVRPhone = (ivrId) => api.delete(`/api/ivr_phones/${ivrId}/`);

export const getTargetList = () => api.get('/api/targets/').then((response) => response.data);

export const fetchStories = (params, cities) => api.get(`/api/story/${cities}`, { params })
  .then((response) => {
    response.data.stories = serializeStoriesList(response.data.stories);
    return response.data;
  });

export const fetchStoryById = (id) => api.get(`/api/story/${id}/`)
  .then((response) => {
    const story = response.data;
    story.id = id;
    return serializeStory(story);
  });

export const createStory = (story) => api.post('/api/story/', deserializeStory(story))
  .then((response) => serializeStory(response.data));

export const updateStory = (story) => api.put(`/api/story/${story.id}/`, deserializeStory(story))
  .then((response) => serializeStory(response.data));

export const deleteStory = (id) => api.delete(`/api/story${id}/`);

export const fetchStoryPersonalized = (id) => api.get(`/api/story/${id}/personalized`)
  .then((response) => serializeStoryPersonalized(response.data));

export const updateStoryPersonalized = (id, personalizedFormData) => api
  .post(`/api/story/${id}/personalized`, personalizedFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const fetchSlides = (storyId) => api.get(`/api/story/${storyId}/slide/`)
  .then((response) => serializeSlidesList(response.data.slides));

export const createSlide = (slide, storyId) => api.post(`/api/story/${storyId}/slide/`,
  deserializeSlide(slide)).then((response) => serializeSlide(response.data));

export const updateSlide = (slide) => api.put(`/api/story/${slide.storyId}/slide/${slide.id}/`, deserializeSlide(slide))
  .then((response) => serializeSlide(response.data));

export const deleteSlide = (slide) => api.delete(`/api/story/${slide.storyId}/slide/${slide.id}/`);

export const getLanguagesList = () => api.get('/api/languages/').then((response) => response.data);

export const fetchSynonymsRequest = (params) => api.get('/api/aliases/', { params })
  .then((response) => {
    response.data.results = serializeSynonymsRequestList(response.data.results);
    return response.data;
  });

export const createSynonymsRequest = (params) => api
  .post('/api/aliases/', deserializeSynonymsRequest(params))
  .then((response) => response.data);

export const updateSynonymsRequest = (id, params) => api
  .put(`/api/aliases/${id}`, deserializeSynonymsRequest(params))
  .then((response) => response.data);

export const deleteSynonymsRequest = (id) => api
  .delete(`/api/aliases/${id}`);

export const fetchSuggestions = (params) => api.get('/api/suggestions/', { params })
  .then((response) => {
    response.data.results = serializeSuggestionsList(response.data.results);
    return response.data;
  });

export const fetchSuggestionById = (id) => api.get(`/api/suggestions/${id}/`)
  .then((response) => {
    const suggestion = response.data;
    suggestion.id = id;
    return serializeSuggestion(suggestion);
  });

export const createSuggestion = (suggestion) => api
  .post('/api/suggestions/', deserializeSuggestion(suggestion))
  .then((response) => serializeSuggestion(response.data));

export const updateSuggestion = (suggestion) => api
  .put(`/api/suggestions/${suggestion.id}/`, deserializeSuggestion(suggestion))
  .then((response) => serializeSuggestion(response.data));

export const deleteSuggestion = (id) => api.delete(`/api/suggestions/${id}/`);
export const getCartEventList = (params) => api
  .get('/api/cartevent/', { params }).then((response) => {
    response.data.cartevents = serializeCartEventList(response.data.cartevents);
    return response.data;
  });

export const createCartEvent = (payload) => api.post('/api/cartevent-with-conditions/', deserializeCartEvent(payload))
  .then((response) => serializeCartEvent(response.data));

export const updateCartEventWithCondition = (payload, carteventId) => api.put(`/api/cartevent-with-conditions/${carteventId}/`, deserializeCartEvent(payload))
  .then((response) => serializeCartEvent(response.data));

export const getCartEventConditionList = (carteventId, params) => api.get(`/api/cartevent/${carteventId}/condition/`, { params })
  .then((response) => serializeCartEventConditionList(response.data.cartevent_conditions));

export const updateCartEvent = (payload, carteventId) => api.put(`/api/cartevent/${carteventId}/`, deserializeCartEvent(payload))
  .then((response) => serializeCartEvent(response.data));

export const deleteCartEventCondition = (carteventId, conditionId) => api.delete(`/api/cartevent/${carteventId}/condition/${conditionId}/`);

export const registerPartner = (payload) => api.post('/partners/partners', payload)
  .then((response) => response.data);

export const getPartnersList = (params) => api.get('/partners/partners', { params })
  .then((response) => {
    response.data.result = serializePartnersList(response.data.result);
    return response.data;
  });

export const getInfoPartner = (id) => api.get(`/partners/partners/${id}`)
  .then((response) => serializePartners(response.data));

export const updatePartner = (id, payload) => api.put(`/partners/partners/${id}`, deserializePartners(payload))
  .then((response) => response.data);

export const updateStatePartner = (id, payload) => api.patch(`/partners/partners/${id}`, payload)
  .then((response) => response.data);

export const resetPasswordPartner = (id) => api.post(`/partners/partners/${id}/reset_password`)
  .then((response) => response.data);

export const getPartnerRestaurants = (id, page = 1) => api.get(`/partners/partners/${id}/restaurants?page=${page}`)
  .then((response) => {
    if (response.data.result) {
      response.data.result = serializePartnerRestaurantsList(response.data.result);
    }
    return response.data;
  });

export const addPartnerRestaurant = (id, payload) => api.post(`/partners/partners/${id}/restaurants`, deserializePartnerRestaurants(payload))
  .then((response) => response.data);

export const removePartnerRestaurant = (id, restId) => api.delete(`/partners/partners/${id}/restaurants/${restId}`)
  .then((response) => response.data);

export const fetchNotifications = (params, targetsCitiesHubs) => api
  .get(`/admin-api/v1/service_notifications/${targetsCitiesHubs}`, { params })
  .then((response) => {
    response.data.result = serializeNotificationsList(response.data.result);
    return response.data;
  });

export const getNotificationById = (id) => api.get(`/admin-api/v1/service_notifications/${id}/`)
  .then((response) => serializeNotification(response.data));

export const createNotification = (notification) => api
  .post('/admin-api/v1/service_notifications/', deserializeNotification(notification))
  .then((response) => serializeNotification(response.data));

export const updateNotification = (notification) => api
  .put(`/admin-api/v1/service_notifications/${notification.id}/`, deserializeNotification(notification))
  .then((response) => serializeNotification(response.data));

export const fetchCollections = (params) => api
  .get('/api/collection/', { params })
  .then((response) => {
    response.data.data = serializeCollectionList(response.data.data);
    return response.data;
  });

export const fetchCollectionById = (id) => api.get(`/api/collection/${id}/`)
  .then((response) => serializeCollection(response.data));

export const fetchCollectionPresets = (params) => api.get('/api/collection/presets/', { params })
  .then((response) => serializeCollectionPresetList(response.data));

export const createCollection = (collection) => api
  .post('/api/collection/', deserializeCollection(collection));

export const updateCollection = (collection) => api
  .put(`/api/collection/${collection.id}/`, deserializeCollection(collection));

export const deleteCollection = (id) => api.delete(`/api/collection/${id}/`);

export const fetchIncomePartners = (params) => api
  .get('/admin-api/v1/income_partners/', { params })
  .then((response) => {
    response.data.result = response.data ? serializeIncomePartnersList(response.data.result) : [];
    return response.data;
  });

export const assignManagerToIncomePartner = (incomePartner) => api
  .post(`/admin-api/v1/income_partners/${incomePartner.id}/assign/`, deserializeIncomePartner(incomePartner));

export const markIncomePartnerAsProcessed = (incomePartner) => api
  .post(`/admin-api/v1/income_partners/${incomePartner.id}/processed/`, deserializeIncomePartner(incomePartner));

export const fetchChips = (params) => api.get('/reviews/v1/chips', { params })
  .then((response) => {
    if (response.data.result) {
      response.data.result.reviews = serializeChipList(response.data.result.reviews);
    }
    return response.data.result;
  });

export const createChip = (chip) => api.post('/reviews/v1/chips', deserializeChip(chip));

export const updateChip = (chip) => api.put(`/reviews/v1/chips/${chip.id}`, deserializeChip(chip));

export const createContractPrepaymentSchedule = (prepayment) => api.post(
  '/api/contracts/contract_prepayment_schedules/',
  deserializeContractPrepayment(prepayment),
);

export const updateContractPrepaymentSchedule = (prepayment) => api.patch(
  `/api/contracts/contract_prepayment_schedules/${prepayment.id}/`,
  deserializeContractPrepayment(prepayment),
);

export const getContractPrepaymentSchedules = (contractId) => api
  .get(`/api/contracts/${contractId}/contract_prepayment_schedules/`)
  .then((response) => serializeContractPrepaymentList(response.data));

export const deleteContractPrepaymentSchedule = (prepaymentId) => api
  .delete(`/api/contracts//contract_prepayment_schedules/${prepaymentId}/`);

export const fetchPolygons = (cityId, page, pageSize = 100) => api.get(`/api/hexagon-map/${cityId}/?page=${page}&page_size=${pageSize}`);

export const fetchRestaurantsByKeys = (cityId, areaKeys, page, pageSize = 500) => api.post(`/api/hexagon-map/${cityId}/restaurants/?page=${page}&page_size=${pageSize}`, {
  area_keys: areaKeys,
});

export const fetchAllRestaurants = (cityId, page, pageSize = 500) => api.post(`/api/hexagon-map/${cityId}/restaurants/?page=${page}&page_size=${pageSize}`, {});

export const fetchSRIntegrations = (params) => api.get('/partners/sr/integrations/', { params })
  .then((response) => serializeIntegrationList(response.data?.result));

export const fetchSRIntegrationOrganizations = (apiLogin) => api.get('/partners/sr/integrations/organizations/',
  { params: { iiko_api_login: apiLogin } })
  .then((response) => serializeIntegrationDataList(response.data));

export const createSRIntegration = (integration) => api
  .post('/partners/sr/integrations/', deserializeIntegration(integration));

export const updateSRIntegration = (integration) => api
  .put(`/partners/sr/integrations/${integration.id}/`, deserializeIntegration(integration));

export const deleteSRIntegration = (id) => api.delete(`/partners/sr/integrations/${id}/`);

export const getIIKOToken = (apiLogin) => iiko.post('api/1/access_token', { apiLogin })
  .then((response) => {
    if (response.data?.token) {
      iiko.defaults.headers.Authorization = `Bearer ${response.data.token}`;
    }
  });

export const getIIKOOrganizations = () => iiko.post('api/1/organizations', {
  organizationIds: ['497f6eca-6276-4993-bfeb-53cbbbba6f08'],
  returnAdditionalInfo: false,
  includeDisabled: false,
  returnExternalData: [],
}).then((response) => serializeIntegrationDataList(response.data?.organizations));

export const getIIKOOrganizationData = (id) => iiko.post('api/1/nomenclature', {
  organizationId: id,
  startRevision: 0,
}).then((response) => serializeIntegrationDataList(response.data?.groups));

export const getIIKOPaymentTypes = (id) => iiko.post('api/1/payment_types', {
  organizationIds: [id],
}).then((response) => serializeIntegrationDataList(response.data?.paymentTypes));

export const getIIKODeliveryTypes = (id) => iiko.post('api/1/deliveries/order_types', {
  organizationIds: [id],
}).then((response) => serializeIntegrationDataList(response.data?.orderTypes[0]?.items));

export const getIIKOTerminalGroups = (id) => iiko.post('api/1/terminal_groups', {
  organizationIds: [id],
}).then((response) => serializeIntegrationDataList(response.data?.terminalGroups[0]?.items));

export const downloadAreas = (restId, isReduced) => api
  .get(`/api/delivery_areas/restaurants/${restId}/export/?is_reduced_area=${isReduced}`);
